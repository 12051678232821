<template>
  <div v-if="doc">
    <v-card>
      <v-row no-gutters class="mb-3 itemFieldName ml-3">
        <v-col class="subTitle" cols="5">Chat</v-col>
        <v-col class="subTitle" cols="3">Client Name</v-col>
        <v-col class="subTitle" cols="2">Status</v-col>
      </v-row>
      <v-list>
        <v-list v-for="(job, i) in list.docs" :key="i">
          <v-row
            class="mb-3 pb-2 ml-3 text"
            no-gutters
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
          >
            <v-col class="subTitle" cols="5" style="align-self: center">
              {{ job.data.toChat || '-' }}
            </v-col>
            <v-col class="subTitle" cols="3" style="align-self: center">
              {{ job.profile && job.profile.name }}
            </v-col>
            <v-col class="subTitle" cols="2" style="align-self: center">
              {{ job.completed ? 'Success' : 'Failed' }}
            </v-col>
            <v-col class="subTitle" cols="2" v-if="!job.completed">
              <v-btn @click="resend(job._id)" color="danger" style="align-self: center">
                Resend
              </v-btn>
            </v-col>
          </v-row>
        </v-list>
      </v-list>
      <v-pagination :total-visible="11" v-model="pageVal" :length="list.pages || 1" />
    </v-card>
  </div>
</template>

<script>
import api from '@/api';
import { readMessageQueue, reschedule } from '@/api/agenda';

export default {
  name: 'BulkMessageDetails',
  props: {
    doc: {},
  },
  data() {
    return {
      page: 1,
      list: {
        docs: [],
        total: 0,
        pages: 1,
      },
    };
  },
  watch: {
    doc: {
      immediate: true,
      handler() {
        this.page = 1;
        this.reload();
      },
    },
  },
  computed: {
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
  methods: {
    async resend(id) {
      try {
        await reschedule(api, id);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Resend',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        // no error
      }
    },
    async reload() {
      this.list = await readMessageQueue(api, { bmid: this.doc._id, page: this.page });
    },
  },
};
</script>

<style></style>
