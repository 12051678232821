import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {*} params
 */
export async function readMessageQueue(api, params) {
  return (await api.get(`im/chat/bulkMessageJobs`, { params })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{limit?: number, offset?: number, sort?: *}} [params]
 */
export async function bulkMessageAutocomplete(api, params) {
  return (await api.get(`im/chat/bulkMessageAutocomplete`, { params })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 */
export async function reschedule(api, id) {
  return (await api.post(`im/chat/reschedule/${id}`)).data;
}
