<template>
  <v-container class="pa-3">
    <v-dialog v-model="isShow">
      <bulkMessageDetails :doc="job" />
    </v-dialog>
    <CustomDialog ref="confirm" />
    <div class="mainTitle">Bulk Message</div>
    <v-textarea label="Message Content" v-model="body"></v-textarea>
    <div style="display: flex"></div>
    <v-row>
      <v-col class="d-flex flex-row">
        <labelWithTextField v-if="isManager" v-model="clientTags" itemFieldName="Client Tags">
          <template v-slot="{ value, on, attrs }">
            <div class="d-flex align-center">
              <v-autocomplete
                :value="value"
                v-on="on"
                v-bind="attrs"
                :items="autocompleteClientTags"
                label="Send to All"
                dense
                chips
                small-chips
                multiple
                solo
                clearable
                hide-details
              />
              <div class="mx-3 text--secondary" v-if="previewSend">
                {{
                  `Will send to ${previewSend.toChat} existing chats` +
                  (previewSend.toProfile > 0
                    ? `, and ${previewSend.toProfile} new clients via WhatsApp`
                    : '')
                }}
              </div>
            </div>
          </template>
        </labelWithTextField>
        <v-spacer />
        <v-btn color="success" class="mb-3" @click="send" :disabled="!body.trim()">Send</v-btn>
      </v-col>
    </v-row>
    <v-divider style="margin-top: 24px" />
    <div class="mainTitle">Bulk Message Queue</div>
    <div class="mt-5">
      <v-card>
        <v-card-text>
          <v-list>
            <v-row>
              <v-col md="1" class="ml-auto">
                <v-btn small @click="reload"><v-icon>mdi-reload</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-3 itemFieldName">
              <v-col class="subTitle" cols="5">Content</v-col>
              <v-col class="subTitle" cols="3">Status</v-col>
              <v-col class="subTitle" cols="2">Failed</v-col>
            </v-row>
            <v-list v-for="(job, i) in queue.docs" :key="i">
              <v-row
                class="mb-3 pb-2 text"
                no-gutters
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
              >
                <v-col class="subTitle" cols="5">{{ job.data.body }}</v-col>
                <v-col class="subTitle" cols="3">
                  Complete: {{ job.completed }}/{{ job.total }}
                </v-col>
                <v-col class="subTitle" cols="2">{{ job.failed }} </v-col>
                <v-col class="subTitle" cols="2"
                  ><v-btn class="mr-3" small v-if="job.fail > 0">Resend</v-btn
                  ><v-btn small @click="show(job)">Details</v-btn></v-col
                >
              </v-row>
            </v-list>
          </v-list>
          <v-pagination class="mt-4" v-model="pageVal" :length="totalPage" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import api from '@/api';
import { chatList, queueMessage } from '@/api/chat';
import { getMyTeam } from '@/api/team';
import CustomDialog from '@/components/CustomDialog';
import labelWithTextField from '@/components/customFormLabel/labelWithTextField';
import { mapGetters } from 'vuex';
import { bulkMessageAutocomplete } from '@/api/agenda';
import bulkMessageDetails from '@/components/BulkMessage/BulkMessageQueue';

export default {
  name: 'BulkMessage',
  components: { CustomDialog, labelWithTextField, bulkMessageDetails },
  data() {
    return {
      body: '',
      clientTags: [],
      autocompleteClientTags: [],
      /** @type {{ total: number, toChat: number, toProfile: number }} */
      previewSend: null,
      queue: {
        docs: [],
        total: 0,
        offset: 0,
      },
      page: 1,
      job: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isManager']),
    isShow: {
      get() {
        return !!this.job;
      },
      set(val) {
        if (!val) {
          this.job = null;
        }
      },
    },
    totalPage() {
      return Math.ceil(this.queue.total / this.queue.limit) || 1;
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.queue.offset = 10 * (this.page - 1);
        this.reload();
      },
    },
    computedFilter() {
      if (this.clientTags.length) {
        return { _clientTags: { $in: this.clientTags } };
      } else {
        return undefined;
      }
    },
    computedFilterWatcher() {
      return JSON.stringify(this.computedFilter);
    },
  },
  mounted() {
    this.reload();
  },
  watch: {
    computedFilterWatcher: {
      immediate: true,
      handler() {
        this.reloadPreviewSend();
      },
    },
  },
  methods: {
    show(job) {
      this.job = job;
    },
    async reloadPreviewSend() {
      const tags = this.clientTags.length ? this.clientTags : false;
      this.previewSend = await queueMessage(api, {
        body: '',
        preview: true,
        clientTag: tags,
        platform: 'chatapi' /* todo: hard coded chatapi */,
      });
    },
    async reload() {
      this.queue = await bulkMessageAutocomplete(api, {
        sort: { lastFinishedAt: -1 },
        offset: this.queue.offset,
      });
      if (this.isManager) {
        this.autocompleteClientTags = (await getMyTeam(api)).clientTags.filter(x => x);
      }
    },
    async send() {
      try {
        await this.reloadPreviewSend();
        if (
          !(await this.$refs.confirm.open(
            'Reminder',
            `You are going to send this message to ${this.previewSend.total} chats.`,
            { confirmBtn: 'Confirm', cancelBtn: 'Cancel' },
          ))
        ) {
          return;
        }
        const tags = this.clientTags.length ? this.clientTags : false;
        await queueMessage(api, {
          body: this.body,
          clientTag: tags,
          platform: 'chatapi' /* todo: hard coded chatapi */,
        });

        this.body = '';
        await this.reload();
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
};
</script>
